var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { SubscriptionBundlesCollection, } from "@bundles/UIAppBundle/collections";
let SubscriptionBundleCreateForm = class SubscriptionBundleCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "accessName",
                label: t("management.subscription_bundles.fields.accessName"),
                name: ["accessName"],
                required: true,
                render: (props) => {
                    const options = [
                        { value: "LITE", label: "Lite" },
                        { value: "PLUS", label: "Plus" },
                        { value: "CUSTOM", label: "Custom" },
                    ];
                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.accessName") }, { children: options.map((option) => {
                                var _a;
                                return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.accessName_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                            }) }), void 0) }), void 0));
                },
            },
            {
                id: "benefits",
                label: t("management.subscription_bundles.fields.benefits"),
                name: ["benefits"],
                required: true,
                nest: [
                    {
                        id: "freeTrial",
                        label: t("management.subscription_bundles.fields.benefits.freeTrial"),
                        name: ["benefits", "freeTrial"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                    {
                        id: "users",
                        label: t("management.subscription_bundles.fields.benefits.users"),
                        name: ["benefits", "users"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                    {
                        id: "pricePerCredit",
                        label: t("management.subscription_bundles.fields.benefits.pricePerCredit"),
                        name: ["benefits", "pricePerCredit"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                    {
                        id: "dealAnalyzer",
                        label: t("management.subscription_bundles.fields.benefits.dealAnalyzer"),
                        name: ["benefits", "dealAnalyzer"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "YES", label: "Yes" },
                                { value: "NO", label: "No" },
                                { value: "CONTACT_SALES", label: "Contact Sales" },
                                { value: "COMING_SOON", label: "Coming Soon" },
                                { value: "UNLIMITED", label: "Unlimited" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.dealAnalyzer") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.dealAnalyzer_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "auditGenerator",
                        label: t("management.subscription_bundles.fields.benefits.auditGenerator"),
                        name: ["benefits", "auditGenerator"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "YES", label: "Yes" },
                                { value: "NO", label: "No" },
                                { value: "CONTACT_SALES", label: "Contact Sales" },
                                { value: "COMING_SOON", label: "Coming Soon" },
                                { value: "UNLIMITED", label: "Unlimited" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.auditGenerator") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.auditGenerator_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "revenueForecaster",
                        label: t("management.subscription_bundles.fields.benefits.revenueForecaster"),
                        name: ["benefits", "revenueForecaster"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "YES", label: "Yes" },
                                { value: "NO", label: "No" },
                                { value: "CONTACT_SALES", label: "Contact Sales" },
                                { value: "COMING_SOON", label: "Coming Soon" },
                                { value: "UNLIMITED", label: "Unlimited" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.revenueForecaster") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.revenueForecaster_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "localBusinessSearch",
                        label: t("management.subscription_bundles.fields.benefits.localBusinessSearch"),
                        name: ["benefits", "localBusinessSearch"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "YES", label: "Yes" },
                                { value: "NO", label: "No" },
                                { value: "CONTACT_SALES", label: "Contact Sales" },
                                { value: "COMING_SOON", label: "Coming Soon" },
                                { value: "UNLIMITED", label: "Unlimited" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.localBusinessSearch") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.localBusinessSearch_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "whiteLabelFulfillment",
                        label: t("management.subscription_bundles.fields.benefits.whiteLabelFulfillment"),
                        name: ["benefits", "whiteLabelFulfillment"],
                        required: true,
                        component: Ant.Input,
                        isList: true,
                    },
                    {
                        id: "fulfillmentPricing",
                        label: t("management.subscription_bundles.fields.benefits.fulfillmentPricing"),
                        name: ["benefits", "fulfillmentPricing"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "STANDARD", label: "Standard" },
                                { value: "PREFERRED", label: "Preferred" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.fulfillmentPricing") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.fulfillmentPricing_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "resources",
                        label: t("management.subscription_bundles.fields.benefits.resources"),
                        name: ["benefits", "resources"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "LIMITED", label: "Limited" },
                                {
                                    value: "FULL_WHITE_LABEL_ACCESS_AND_LICENSING",
                                    label: "Full White Label Access And Licensing",
                                },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.resources") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.resources_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "courses",
                        label: t("management.subscription_bundles.fields.benefits.courses"),
                        name: ["benefits", "courses"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "A_LA_CARTE", label: "A La Carte" },
                                { value: "INTRO", label: "Intro" },
                                { value: "ELITE", label: "Elite" },
                                { value: "ALL", label: "All" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.courses") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.courses_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "groupCoaching",
                        label: t("management.subscription_bundles.fields.benefits.groupCoaching"),
                        name: ["benefits", "groupCoaching"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "NO", label: "No" },
                                { value: "YES", label: "Yes" },
                                { value: "LIMITED", label: "Limited" },
                                { value: "DAILY", label: "Daily" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.groupCoaching") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.groupCoaching_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "internalTeamMessaging",
                        label: t("management.subscription_bundles.fields.benefits.internalTeamMessaging"),
                        name: ["benefits", "internalTeamMessaging"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "YES", label: "Yes" },
                                { value: "NO", label: "No" },
                                { value: "CONTACT_SALES", label: "Contact Sales" },
                                { value: "COMING_SOON", label: "Coming Soon" },
                                { value: "UNLIMITED", label: "Unlimited" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.internalTeamMessaging") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.internalTeamMessaging_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "showOnHomepage",
                        label: t("management.subscription_bundles.fields.benefits.showOnHomepage"),
                        name: ["benefits", "showOnHomepage"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "YES", label: "Yes" },
                                { value: "NO", label: "No" },
                                { value: "CONTACT_SALES", label: "Contact Sales" },
                                { value: "COMING_SOON", label: "Coming Soon" },
                                { value: "UNLIMITED", label: "Unlimited" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.showOnHomepage") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.showOnHomepage_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "CRM",
                        label: t("management.subscription_bundles.fields.benefits.CRM"),
                        name: ["benefits", "CRM"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "YES", label: "Yes" },
                                { value: "NO", label: "No" },
                                { value: "CONTACT_SALES", label: "Contact Sales" },
                                { value: "COMING_SOON", label: "Coming Soon" },
                                { value: "UNLIMITED", label: "Unlimited" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.CRM") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.CRM_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "leadGenerator",
                        label: t("management.subscription_bundles.fields.benefits.leadGenerator"),
                        name: ["benefits", "leadGenerator"],
                        required: true,
                        nest: [
                            {
                                id: "linkedin",
                                label: t("management.subscription_bundles.fields.benefits.leadGenerator.linkedin"),
                                name: ["benefits", "leadGenerator", "linkedin"],
                                required: true,
                                render: (props) => {
                                    const options = [
                                        { value: "YES", label: "Yes" },
                                        { value: "NO", label: "No" },
                                        { value: "CONTACT_SALES", label: "Contact Sales" },
                                        { value: "COMING_SOON", label: "Coming Soon" },
                                        { value: "UNLIMITED", label: "Unlimited" },
                                    ];
                                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.leadGenerator.linkedin") }, { children: options.map((option) => {
                                                var _a;
                                                return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.leadGenerator.linkedin_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                            }) }), void 0) }), void 0));
                                },
                            },
                            {
                                id: "instagram",
                                label: t("management.subscription_bundles.fields.benefits.leadGenerator.instagram"),
                                name: ["benefits", "leadGenerator", "instagram"],
                                required: true,
                                render: (props) => {
                                    const options = [
                                        { value: "YES", label: "Yes" },
                                        { value: "NO", label: "No" },
                                        { value: "CONTACT_SALES", label: "Contact Sales" },
                                        { value: "COMING_SOON", label: "Coming Soon" },
                                        { value: "UNLIMITED", label: "Unlimited" },
                                    ];
                                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.leadGenerator.instagram") }, { children: options.map((option) => {
                                                var _a;
                                                return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.leadGenerator.instagram_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                            }) }), void 0) }), void 0));
                                },
                            },
                        ],
                    },
                    {
                        id: "outboundEmails",
                        label: t("management.subscription_bundles.fields.benefits.outboundEmails"),
                        name: ["benefits", "outboundEmails"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "YES", label: "Yes" },
                                { value: "NO", label: "No" },
                                { value: "CONTACT_SALES", label: "Contact Sales" },
                                { value: "COMING_SOON", label: "Coming Soon" },
                                { value: "UNLIMITED", label: "Unlimited" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.outboundEmails") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.outboundEmails_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "oneOnOneCoaching",
                        label: t("management.subscription_bundles.fields.benefits.oneOnOneCoaching"),
                        name: ["benefits", "oneOnOneCoaching"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "YES", label: "Yes" },
                                { value: "NO", label: "No" },
                                { value: "CONTACT_SALES", label: "Contact Sales" },
                                { value: "COMING_SOON", label: "Coming Soon" },
                                { value: "UNLIMITED", label: "Unlimited" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.oneOnOneCoaching") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.oneOnOneCoaching_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "leads",
                        label: t("management.subscription_bundles.fields.benefits.leads"),
                        name: ["benefits", "leads"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "YES", label: "Yes" },
                                { value: "NO", label: "No" },
                                { value: "CONTACT_SALES", label: "Contact Sales" },
                                { value: "COMING_SOON", label: "Coming Soon" },
                                { value: "UNLIMITED", label: "Unlimited" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.leads") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.leads_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "dfySetup",
                        label: t("management.subscription_bundles.fields.benefits.dfySetup"),
                        name: ["benefits", "dfySetup"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "YES", label: "Yes" },
                                { value: "NO", label: "No" },
                                { value: "CONTACT_SALES", label: "Contact Sales" },
                                { value: "COMING_SOON", label: "Coming Soon" },
                                { value: "UNLIMITED", label: "Unlimited" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.dfySetup") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.dfySetup_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "guaranteedLeads",
                        label: t("management.subscription_bundles.fields.benefits.guaranteedLeads"),
                        name: ["benefits", "guaranteedLeads"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "YES", label: "Yes" },
                                { value: "NO", label: "No" },
                                { value: "CONTACT_SALES", label: "Contact Sales" },
                                { value: "COMING_SOON", label: "Coming Soon" },
                                { value: "UNLIMITED", label: "Unlimited" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.guaranteedLeads") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.guaranteedLeads_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "outboundSms",
                        label: t("management.subscription_bundles.fields.benefits.outboundSms"),
                        name: ["benefits", "outboundSms"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "YES", label: "Yes" },
                                { value: "NO", label: "No" },
                                { value: "CONTACT_SALES", label: "Contact Sales" },
                                { value: "COMING_SOON", label: "Coming Soon" },
                                { value: "UNLIMITED", label: "Unlimited" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.outboundSms") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.outboundSms_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "whiteLabelDashboard",
                        label: t("management.subscription_bundles.fields.benefits.whiteLabelDashboard"),
                        name: ["benefits", "whiteLabelDashboard"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "YES", label: "Yes" },
                                { value: "NO", label: "No" },
                                { value: "CONTACT_SALES", label: "Contact Sales" },
                                { value: "COMING_SOON", label: "Coming Soon" },
                                { value: "UNLIMITED", label: "Unlimited" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.whiteLabelDashboard") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.whiteLabelDashboard_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "aiArticleGenerator",
                        label: t("management.subscription_bundles.fields.benefits.aiArticleGenerator"),
                        name: ["benefits", "aiArticleGenerator"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "YES", label: "Yes" },
                                { value: "NO", label: "No" },
                                { value: "CONTACT_SALES", label: "Contact Sales" },
                                { value: "COMING_SOON", label: "Coming Soon" },
                                { value: "UNLIMITED", label: "Unlimited" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.aiArticleGenerator") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.aiArticleGenerator_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "aiProposalGenerator",
                        label: t("management.subscription_bundles.fields.benefits.aiProposalGenerator"),
                        name: ["benefits", "aiProposalGenerator"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "YES", label: "Yes" },
                                { value: "NO", label: "No" },
                                { value: "CONTACT_SALES", label: "Contact Sales" },
                                { value: "COMING_SOON", label: "Coming Soon" },
                                { value: "UNLIMITED", label: "Unlimited" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.aiProposalGenerator") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.aiProposalGenerator_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "fulfillmentCenter",
                        label: t("management.subscription_bundles.fields.benefits.fulfillmentCenter"),
                        name: ["benefits", "fulfillmentCenter"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "YES", label: "Yes" },
                                { value: "NO", label: "No" },
                                { value: "CONTACT_SALES", label: "Contact Sales" },
                                { value: "COMING_SOON", label: "Coming Soon" },
                                { value: "UNLIMITED", label: "Unlimited" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.fulfillmentCenter") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.fulfillmentCenter_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "resourceCenter",
                        label: t("management.subscription_bundles.fields.benefits.resourceCenter"),
                        name: ["benefits", "resourceCenter"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "YES", label: "Yes" },
                                { value: "NO", label: "No" },
                                { value: "CONTACT_SALES", label: "Contact Sales" },
                                { value: "COMING_SOON", label: "Coming Soon" },
                                { value: "UNLIMITED", label: "Unlimited" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.resourceCenter") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.resourceCenter_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "tools",
                        label: t("management.subscription_bundles.fields.benefits.tools"),
                        name: ["benefits", "tools"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "YES", label: "Yes" },
                                { value: "NO", label: "No" },
                                { value: "CONTACT_SALES", label: "Contact Sales" },
                                { value: "COMING_SOON", label: "Coming Soon" },
                                { value: "UNLIMITED", label: "Unlimited" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.tools") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.tools_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "mediaSpotlight",
                        label: t("management.subscription_bundles.fields.benefits.mediaSpotlight"),
                        name: ["benefits", "mediaSpotlight"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "NO", label: "No" },
                                { value: "YES", label: "Yes" },
                                { value: "STANDARD", label: "Standard" },
                                { value: "FEATURED", label: "Featured" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_bundles.fields.benefits.mediaSpotlight") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_bundles.fields.benefits.mediaSpotlight_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                ],
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.subscription_bundles.create_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
            if (features.view) {
                return this.router.go(Routes.SUBSCRIPTION_BUNDLES_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.SUBSCRIPTION_BUNDLES_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.SUBSCRIPTION_BUNDLES_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => SubscriptionBundlesCollection),
    __metadata("design:type", typeof (_a = typeof SubscriptionBundlesCollection !== "undefined" && SubscriptionBundlesCollection) === "function" ? _a : Object)
], SubscriptionBundleCreateForm.prototype, "collection", void 0);
SubscriptionBundleCreateForm = __decorate([
    Service({ transient: true })
], SubscriptionBundleCreateForm);
export { SubscriptionBundleCreateForm };
