var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { ImagesCollection, ServiceItemsCollection, ResourcesCollection, } from "@bundles/UIAppBundle/collections";
let ResourceEditForm = class ResourceEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.resources.fields.name"),
                name: ["name"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "description",
                label: t("management.resources.fields.description"),
                name: ["description"],
                component: Ant.Input,
            },
            {
                id: "fileUrl",
                label: t("management.resources.fields.fileUrl"),
                name: ["fileUrl"],
                component: Ant.Input,
            },
            {
                id: "types",
                label: t("management.resources.fields.types"),
                name: ["types"],
                initialValue: [],
                render: (props) => {
                    const options = [
                        { value: "CASE_STUDY", label: "Case Study" },
                        { value: "PRESENTATION", label: "Presentation" },
                        { value: "TEMPLATE", label: "Template" },
                        { value: "INDUSTRY_REPORT", label: "Industry Report" },
                        { value: "KICKOFF_CALL_TEMPLATE", label: "Kickoff Call Template" },
                        { value: "FREE_GUIDE_OR_EBOOK", label: "Free Guide Or Ebook" },
                    ];
                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ allowClear: true, mode: "multiple", placeholder: t("management.resources.fields.types") }, { children: options.map((option) => {
                                var _a;
                                return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.resources.fields.types_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                            }) }), void 0) }), void 0));
                },
            },
            {
                id: "industries",
                label: t("management.resources.fields.industries"),
                name: ["industries"],
                initialValue: [],
                render: (props) => {
                    const options = [
                        { value: "AUTOMOTIVE", label: "Automotive" },
                        { value: "RESTAURANT", label: "Restaurant" },
                        { value: "SMOKE_SHOP", label: "Smoke Shop" },
                        { value: "ECOMMERCE", label: "Ecommerce" },
                        { value: "PLUMBING", label: "Plumbing" },
                        { value: "GENERAL", label: "General" },
                        { value: "MARKETING_AGENCIES", label: "Marketing Agencies" },
                        { value: "REAL_ESTATE", label: "Real Estate" },
                        { value: "LOCAL_ART_STUDIO", label: "Local Art Studio" },
                        { value: "PIZZA_COMPANY", label: "Pizza Company" },
                        { value: "HAIR_STYLIST", label: "Hair Stylist" },
                        {
                            value: "ONLINE_COACHES_CONSULTANTS",
                            label: "Online Coaches Consultants",
                        },
                        { value: "SOLAR_INDUSTRY", label: "Solar Industry" },
                        { value: "ROOFING", label: "Roofing" },
                        { value: "PERSONAL_TRAINERS", label: "Personal Trainers" },
                        { value: "SNACK_COMPANY", label: "Snack Company" },
                        { value: "BEAUTY_WELLNESS", label: "Beauty Wellness" },
                        { value: "FITNESS_GYMS", label: "Fitness Gyms" },
                        { value: "CROSS_FIT_GYMS", label: "Cross Fit Gyms" },
                        { value: "CHIROPRACTOR", label: "Chiropractor" },
                        { value: "DENTAL", label: "Dental" },
                        { value: "ATTORNEYS", label: "Attorneys" },
                        { value: "CONCREATE_COMPANY", label: "Concreate Company" },
                        { value: "TV_SHOW", label: "Tv Show" },
                        { value: "HEALTH", label: "Health" },
                        { value: "LOGO_EXAMPLES", label: "Logo Examples" },
                        { value: "GENERAL_AD_EXAMPLES", label: "General Ad Examples" },
                        { value: "LAW_AD_EXAMPLES", label: "Law Ad Examples" },
                        {
                            value: "RESTAURANT_AD_EXAMPLES",
                            label: "Restaurant Ad Examples",
                        },
                        { value: "HEALTH_AD_EXAMPLES", label: "Health Ad Examples" },
                        {
                            value: "ORTHODONTIST_AD_EXAMPLES",
                            label: "Orthodontist Ad Examples",
                        },
                        { value: "FITNESS_AD_EXAMPLES", label: "Fitness Ad Examples" },
                        { value: "DENTAL_AD_EXAMPLES", label: "Dental Ad Examples" },
                        { value: "INSURANCE_AD_EXAMPLES", label: "Insurance Ad Examples" },
                        { value: "FINANCE_AD_EXAMPLES", label: "Finance Ad Examples" },
                        {
                            value: "AUTOMOTIVE_AD_EXAMPLES",
                            label: "Automotive Ad Examples",
                        },
                        {
                            value: "HAIR_STYLIST_AD_EXAMPLES",
                            label: "Hair Stylist Ad Examples",
                        },
                        { value: "GENERAL_SEO_EXAMPLE", label: "General Seo Example" },
                        {
                            value: "REAL_ESTATE_SEO_EXAMPLE",
                            label: "Real Estate Seo Example",
                        },
                        { value: "FAMILY_EYE_CARE_SEO", label: "Family Eye Care Seo" },
                        { value: "PLUMBING_SEO_EXAMPLE", label: "Plumbing Seo Example" },
                        { value: "ATTORNEY_SEO_EXAMPLE", label: "Attorney Seo Example" },
                        {
                            value: "SOCIAL_GROWTH_PROOF_RESOURCES",
                            label: "Social Growth Proof Resources",
                        },
                    ];
                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ allowClear: true, mode: "multiple", placeholder: t("management.resources.fields.industries") }, { children: options.map((option) => {
                                var _a;
                                return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.resources.fields.industries_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                            }) }), void 0) }), void 0));
                },
            },
            {
                id: "fileId",
                label: t("management.resources.fields.file"),
                name: ["fileId"],
                component: UIComponents.AdminFileUpload,
                componentProps: { field: "_id" },
            },
            {
                id: "logoId",
                label: t("management.resources.fields.logo"),
                name: ["logoId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ImagesCollection, field: "_id", required: false }, void 0) }), void 0)),
            },
            {
                id: "recommendedForServicesIds",
                label: t("management.resources.fields.recommendedForServices"),
                name: ["recommendedForServicesIds"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ServiceItemsCollection, field: "name", required: true, mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: 1,
            description: 1,
            fileUrl: 1,
            types: 1,
            industries: 1,
            file: {
                _id: 1,
                downloadUrl: 1,
                name: 1,
            },
            fileId: 1,
            logo: {
                _id: 1,
            },
            logoId: 1,
            recommendedForServices: {
                _id: 1,
                name: 1,
            },
            recommendedForServicesIds: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.resources.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => ResourcesCollection),
    __metadata("design:type", typeof (_a = typeof ResourcesCollection !== "undefined" && ResourcesCollection) === "function" ? _a : Object)
], ResourceEditForm.prototype, "collection", void 0);
ResourceEditForm = __decorate([
    Service({ transient: true })
], ResourceEditForm);
export { ResourceEditForm };
