var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { UserViewer as BaseUserViewer } from "./UserViewer.base";
let UserViewer = class UserViewer extends BaseUserViewer {
    build() {
        super.build();
        // Perform additional modifications such as updating rendering functions, labels, description
        this.add([
            {
                id: "phoneNumber",
                label: "phoneNumber",
                dataIndex: ["business", "phoneNumber"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(this.UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
        this.remove("isEmailVerified");
        this.remove("isSignupCompleted");
        this.remove("isFirstLogin");
        this.remove("roles");
        this.remove("updatedAt");
        this.remove("createdAt");
        this.remove("isEnabled");
        this.remove("profile.firstName");
        this.remove("profile.lastName");
        this.remove("createdBy");
        this.remove("updatedBy");
        this.remove("business");
        this.remove("profilePhoto");
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        const requestBody = super.getRequestBody();
        Object.assign(requestBody, {
            business: {
                phoneNumber: 1,
            },
        });
        return requestBody;
    }
};
UserViewer = __decorate([
    Service({ transient: true })
], UserViewer);
export { UserViewer };
