import { SubscriptionBundlesList } from "../components/List/SubscriptionBundlesList";
import { SubscriptionBundlesCreate } from "../components/Create/SubscriptionBundlesCreate";
import { SubscriptionBundlesView } from "../components/View/SubscriptionBundlesView";
import { SettingFilled } from "@ant-design/icons";
export const SUBSCRIPTION_BUNDLES_LIST = {
    path: "/admin/subscription-bundles",
    component: SubscriptionBundlesList,
    menu: {
        key: "SUBSCRIPTION_BUNDLES_LIST",
        label: "management.subscription_bundles.menu.title",
        icon: SettingFilled,
    },
};
export const SUBSCRIPTION_BUNDLES_CREATE = {
    path: "/admin/subscription-bundles/create",
    component: SubscriptionBundlesCreate,
};
export const SUBSCRIPTION_BUNDLES_EDIT = {
    path: "/admin/subscription-bundles/:id/edit",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const SUBSCRIPTION_BUNDLES_VIEW = {
    path: "/admin/subscription-bundles/:id/view",
    component: SubscriptionBundlesView,
};
