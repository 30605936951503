var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
let PostCategoryListFiltersForm = class PostCategoryListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "categoryName",
                label: t("management.post_categories.fields.categoryName"),
                name: ["categoryName"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "type",
                label: t("management.post_categories.fields.type"),
                name: ["type"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.post_categories.fields.type") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "INTRO" }, { children: "Intro" }), "INTRO"), _jsx(Ant.Select.Option, Object.assign({ value: "ELITE" }, { children: "Elite" }), "ELITE"), _jsx(Ant.Select.Option, Object.assign({ value: "PREMIUM" }, { children: "Premium" }), "PREMIUM")] }), void 0) }), void 0)),
            },
            {
                id: "createdAt",
                label: t("management.post_categories.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.post_categories.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "updatedAt",
                label: t("management.post_categories.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.post_categories.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
        ]);
    }
};
PostCategoryListFiltersForm = __decorate([
    Service({ transient: true })
], PostCategoryListFiltersForm);
export { PostCategoryListFiltersForm };
